import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="קטע מספר: 4 מצאלים לבאר רבבה">
      <SectionContent title="קטע מספר: 4 מצאלים לבאר רבבה">
        <div>
          <p>
            <strong>אורך הקטע: 9.85 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            חוזרים מצאלים לדרך השדות (קטע משותף לרצים בסוף מקטע 3 ותחילת מקטע 4)
            כ-1.2 ק&quot;מ עד למפגש עם דרך מימין אשר תוחמת את הפרדס. עוזבים את
            'דרך השדות' ופונים ימינה לכיוון כללי צפון-מזרח עד למפגש עם כביש 222.
            חוצים את הכביש בזהירות ולאחר חציית הכביש רצים ימינה בשוליים השמאליים
            כ-250 מטר עד למפגש עם דרך עפר שבניצב לכביש 222 מצפון בקצה פרדס.
            פונים שמאלה לדרך העפר כ-200 מטר עד ל'גשר התלוי' ולמפגש עם 'דרך נוף
            הבשור'. פונים שמאלה לכיוון כללי צפון-מערב על דרך הנוף. ממשיכים לרוץ
            לאורך הדרך עד למפגש עם דרך עפר משמאל (כיוון מזרח) בק&quot;מ 9.55.
            פונים שמאלה (יורדים מהדרך הראשית) ורצים כ-250 מטר עד מבנה עתיק של
            באר רבבה.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: מגמת ירידה מתונה.
          </p>
          <p>
            <strong>מסלול</strong>: כביש שדות בחלקו הראשון של המקטע, שולי כביש
            ראשי ודרך עפר לבנה במרבית המקטע.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות גבוהה בחציית כביש 222 ובריצה לאורכו.
            ערנות לרכבים ב'דרך נוף הבשור'.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : ברחבת שבסמוך למבנה העתיק של באר רבבה.
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=ueayabgbxmdwnosm"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
